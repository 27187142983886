import React from 'react';
import AuthNavBar from './AuthNavbar';
import GenericNavBar from './GenericNavbar';
import classNames from 'classnames';
import withComponentClassName from '@/utility/withComponentClassName';

function CombinedNavBar({
  isLoggedIn,
  communityInfo,
  userCommunities,
  isCommunityAdmin,
  isCommunityMember,
  isPendingApproval,
  openSignUpModal,
  hasScrolledPastBanner = true,
  joinCommunityButtonCTAProps = null,
  isCommunityPageView = false,
  showEntityCTAButton = false,
  componentClassName = ''
}) {
  return (
    <div
      className={classNames(
        componentClassName,
        `top-0 z-over-intercom flex h-64 w-full items-center px-12`,
        {
          'sticky animate-moveDownLibrary bg-white-default':
            hasScrolledPastBanner
        }
      )}>
      <div className="flex-grow">
        {isLoggedIn ? (
          <AuthNavBar
            communityInfo={communityInfo}
            userCommunities={userCommunities}
            isCommunityAdmin={isCommunityAdmin}
            isCommunityMember={isCommunityMember}
            joinCommunityButtonCTAProps={joinCommunityButtonCTAProps}
            isPendingApproval={isPendingApproval}
            openSignUpModal={openSignUpModal}
          />
        ) : (
          <GenericNavBar
            communityInfo={communityInfo}
            hasScrolledPastBanner={hasScrolledPastBanner}
            openSignUpModal={openSignUpModal}
            isCommunityPageView={isCommunityPageView}
            showEntityCTAButton={showEntityCTAButton}
          />
        )}
      </div>
    </div>
  );
}

export default withComponentClassName(CombinedNavBar, false);
