import NPLButton, { BTN_HIERARCHY } from '@/components/npl/NPLButton';
import React from 'react';
import NextImage from '@/components/common/NextImage';
import { usePathname, useRouter } from 'next/navigation';
import { useWindowWidthContext } from '@/contexts/WindowWidthContext';
import { t } from '@/utility/localization';
import { LOGIN_ROUTE } from '@/utility/routesHelper';
import { trackGAEvent } from '@/utility/analytics';
import {
  MEMBER_NAVBAR_JOIN_COMMUNITY_CLICK,
  MEMBER_NAVBAR_LOGIN_CLICK
} from '@/utility/analyticsConsts';
import { ENTITY_SLUGS_PREFIX } from '@/utility/helpers';
import Icon from '@/components/npl/Icon';

const GenericNavBar = ({
  communityInfo,
  hasScrolledPastBanner,
  openSignUpModal,
  isCommunityPageView = false,
  showEntityCTAButton
}) => {
  const router = useRouter();
  const { isGtEqMd } = useWindowWidthContext();
  const pathname = usePathname();

  const currentRoute = pathname;
  const splittedRoute = currentRoute.split('/');

  const isNotOnHomePageAndEntityPage = splittedRoute.length === 3;
  const isOnEntityPage = splittedRoute.length === 4;

  const routerAsPath = pathname;

  const communityLink = communityInfo?.slug ?? communityInfo?.link;

  const navigateToCommunityHomePage = () => router.push(communityLink);

  const renderLeftSection = () => {
    if (hasScrolledPastBanner || isNotOnHomePageAndEntityPage) {
      return (
        <div className="flex animate-fadeInLibrary items-center gap-8 p-4">
          {isNotOnHomePageAndEntityPage && isCommunityPageView && (
            <div
              onClick={navigateToCommunityHomePage}
              className="hover:cursor-pointer">
              <Icon name="chevron-left" width={16} height={16} />
            </div>
          )}

          {isCommunityPageView ? (
            <div
              className={`flex flex-row items-center gap-2.5 rounded-8 pr-8 transition-all ${
                !isCommunityPageView &&
                'cursor-pointer hover:bg-npl-neutral-light-solid-2'
              }`}
              onClick={() => {
                !isCommunityPageView && navigateToCommunityHomePage();
              }}>
              <div className="h-32">
                <NextImage
                  alt="community profile"
                  mobileImgProps={{
                    src: communityInfo?.profileImage,
                    width: 32,
                    height: 32,
                    objectFit: 'cover'
                  }}
                  className="aspect-square h-32 w-32 rounded-8 object-cover"
                />
              </div>
              <span className="w-[120px] truncate font-semibold md:w-full lg:text-label-lg">
                {communityInfo?.title}
              </span>
            </div>
          ) : (
            <Icon
              path="logo"
              name="nas-io-logo-grayscale"
              width={28}
              height={28}
              fillOpacity={0.5}
            />
          )}
        </div>
      );
    }
    return (
      <Icon
        path="logo"
        name="nas-io-logo-grayscale"
        width={28}
        height={28}
        fillOpacity={0.5}
      />
    );
  };

  const getEntityCTAText = () => {
    const entityType = splittedRoute[2];
    switch (entityType) {
      case ENTITY_SLUGS_PREFIX.CHALLENGES:
        return t('join-challenge');
      case ENTITY_SLUGS_PREFIX.EVENT:
        return t('register');
      default:
        return t('join');
    }
  };

  return (
    <div className="flex items-center justify-between">
      {renderLeftSection()}
      <div className="flex items-center gap-8">
        {isNotOnHomePageAndEntityPage && (
          <NPLButton
            hierarchy={BTN_HIERARCHY.ACCENT_PRIMARY}
            buttonText={isGtEqMd ? t('join-community') : t('join')}
            size="sm"
            onClick={() => {
              openSignUpModal();
              trackGAEvent(MEMBER_NAVBAR_JOIN_COMMUNITY_CLICK, {
                communityId: communityInfo?._id,
                communityCode: communityInfo?.code
              });
            }}
          />
        )}

        {isOnEntityPage && showEntityCTAButton && (
          <NPLButton
            hierarchy={BTN_HIERARCHY.ACCENT_PRIMARY}
            buttonText={getEntityCTAText()}
            size="sm"
            onClick={() => {
              openSignUpModal();
              trackGAEvent(MEMBER_NAVBAR_JOIN_COMMUNITY_CLICK, {
                communityId: communityInfo?._id,
                communityCode: communityInfo?.code
              });
            }}
          />
        )}

        {(isGtEqMd || !isNotOnHomePageAndEntityPage) &&
          !showEntityCTAButton && (
            <NPLButton
              hierarchy={BTN_HIERARCHY.OUTLINE}
              buttonText={t('log-in')}
              size="sm"
              multiply
              onClick={() => {
                trackGAEvent(MEMBER_NAVBAR_LOGIN_CLICK, {
                  communityId: communityInfo?._id,
                  communityCode: communityInfo?.code
                });
                router.push(`${LOGIN_ROUTE}?redirectTo=${routerAsPath}`);
              }}
            />
          )}
      </div>
    </div>
  );
};

export default GenericNavBar;
