import React, { useState } from 'react';
import TitleRow from './TitleRow';
import { t } from '@/utility/localization';
import { useAuthContext } from '@/contexts/AuthContext';
import FloatingLabelInput from '@/components/common/Form/FloatingLabelInput';
import { showErrorToast } from '@/components/common/ToastContainer';
import NPLButton, { BTN_HIERARCHY } from '@/components/npl/NPLButton';
import { COMMUNITY_SIGNUP_FORM_STATE } from '../constants';

const LoginWithPassword = ({
  signupFormValues,
  setFormState,
  handlePostLoginCallback,
  onBack
}) => {
  const { login } = useAuthContext();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const handleSignUpWithPostLogin = async (response) => {
    if (response?.error) {
      showErrorToast(response.error);
      setIsSubmitting(false);
      return;
    }

    const { user } = response?.data || {};
    if (user) {
      handlePostLoginCallback(user);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      // validate
      if (!password) {
        setPasswordError(t('password-is-required'));
        return;
      }

      setIsSubmitting(true);

      login(signupFormValues.email, password, handleSignUpWithPostLogin);
    } catch (e) {
      setIsSubmitting(false);
      showErrorToast(String(e));
    }
  };

  return (
    <div className="c-CommunitySignupModal-LoginWithPassword p-24">
      <TitleRow title={t('enter-your-password')} onBack={onBack} />
      {/* Desc */}
      <p className="mt-8 text-center text-body-sm font-medium text-dark-1b text-opacity-65">
        {t(
          'verification-code-sent-to-email-if-you-dont-see-it-please-check-your-spam-folder',
          {
            email: signupFormValues.email
          }
        )}
      </p>
      <form className="mt-32" onSubmit={onSubmit}>
        <FloatingLabelInput
          type="password"
          name="password"
          placeholder={t('password')}
          label={t('password')}
          floatingLabel={t('password')}
          focus
          onChange={(e) => {
            setPassword(e.target.value);
            setPasswordError('');
          }}
          error={passwordError}
        />
        <div className="mt-32 space-y-12">
          <NPLButton
            stretch
            size="lg"
            type="submit"
            hierarchy={BTN_HIERARCHY.ACCENT_PRIMARY}
            loading={isSubmitting}
            buttonText={t('continue')}
          />
          <NPLButton
            size="lg"
            type="button"
            stretch
            hierarchy={BTN_HIERARCHY.OUTLINE}
            buttonText={t('use-verification-code')}
            onClick={() => {
              setFormState({
                current: COMMUNITY_SIGNUP_FORM_STATE.OTP,
                previous: COMMUNITY_SIGNUP_FORM_STATE.LOGIN_WITH_PASSWORD
              });
            }}
          />
        </div>
      </form>
    </div>
  );
};

export default LoginWithPassword;
